// Helpers ----

const mapboxStyles: MapStyle[] = [
  {name: "Streets", url: "mapbox://styles/mapbox/streets-v12"},
  {name: "Outdoors", url: "mapbox://styles/mapbox/outdoors-v12"},
  {name: "Light", url: "mapbox://styles/mapbox/light-v11"},
  {name: "Dark", url: "mapbox://styles/mapbox/dark-v11"},
  {name: "Satellite", url: "mapbox://styles/mapbox/satellite-v9"},
  {name: "Satellite Streets", url: "mapbox://styles/mapbox/satellite-streets-v12"},
  {name: "Navigation Day", url: "mapbox://styles/mapbox/navigation-day-v1"},
  {name: "Navigation Night", url: "mapbox://styles/mapbox/navigation-night-v1"},
]

// Types ----

type MapStyle = {
  name: string
  url: string
}

// Hook ----

export default function useMapStyle() {
  // Merge custom styles with standard styles
  const allMapStyles = [...mapboxStyles]

  function findMapStyle(mapStyle?: MapStyle|string|null): MapStyle {
    if (typeof mapStyle === 'string') {
      return allMapStyles.find(style => style.name === mapStyle || style.url === mapStyle) ?? mapboxStyles[0];
    }

    return mapStyle ?? mapboxStyles[0];
  }

  // Find the default map style chosen by the operator
  const defaultMapStyle = findMapStyle("Streets");

  return {allMapStyles, defaultMapStyle, findMapStyle}
}