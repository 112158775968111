import {CSSProperties, PropsWithChildren, useState} from "react";
import * as Mapbox from "react-map-gl";
import {ViewStateChangeEvent} from "react-map-gl";
import useMapStyle from "./useMapStyle";
import StyleControl from "./StyleControl";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import "../../services/mapbox-gl";

// Constants ----

const ACCESS_TOKEN = process.env["REACT_APP_MAPBOX_ACCESS_TOKEN"]
const DEFAULT_LONGITUDE = -2.895
const DEFAULT_LATITUDE = 54.093
const DEFAULT_ZOOM = 5


// Types ----

type MapStyle = {
  name: string
  url: string
}

type MapProps = {
  height: CSSProperties['height']
  defaultMapStyle?: MapStyle | string | null
  initialViewState?: Mapbox.MapProps['initialViewState'] | null
  onViewChanged?: (event: ViewStateChangeEvent) => void
  hideFeatures?: string[]
}

// Component ----

function Map({height, defaultMapStyle, initialViewState, onViewChanged, children}: PropsWithChildren<MapProps>) {
  const {findMapStyle} = useMapStyle();

  // Keep track of the currently selected map style
  const [mapStyle, setMapStyle] = useState<MapStyle>(findMapStyle(defaultMapStyle));

  // Prepare the fallback initial view state
  const defaultViewState = {
    longitude: DEFAULT_LONGITUDE,
    latitude: DEFAULT_LATITUDE,
    zoom: DEFAULT_ZOOM,
    padding: {top: 10, bottom: 10, left: 10, right: 10}
  }

  return (
    <Mapbox.Map
      mapboxAccessToken={ACCESS_TOKEN}
      mapStyle={mapStyle.url}
      initialViewState={initialViewState ?? defaultViewState}
      onDragEnd={onViewChanged}
      onZoomEnd={onViewChanged}
      style={{height, borderRadius: 6}}
      cooperativeGestures
    >
      {/* Show Mapbox map controls */}
      <Mapbox.FullscreenControl/>
      <Mapbox.NavigationControl showCompass visualizePitch/>
      <Mapbox.GeolocateControl trackUserLocation showUserLocation showUserHeading showAccuracyCircle={false}/>

      {/* Show custom map controls */}
      <StyleControl selected={mapStyle} onSelect={setMapStyle}/>

      {/* Render additional layers */}
      {children}
    </Mapbox.Map>
  );
}

export default Map;