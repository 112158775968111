import {RadioGroup} from "@headlessui/react";
import {MapIcon} from "@heroicons/react/outline";
import useMapStyle from "./useMapStyle";
import clsx from "clsx";

// Types ----

type MapStyle = {
  name: string
  url: string
}

type StyleControlProps = {
  selected?: MapStyle | string | null
  onSelect?: (mapStyle: MapStyle) => void
}

// Component ----

function StyleControl({onSelect, selected}: StyleControlProps) {
  const {allMapStyles, findMapStyle} = useMapStyle();

  // Find and select the chosen map style
  function selectStyle(mapStyle: MapStyle|string) {
    if(onSelect) onSelect(findMapStyle(mapStyle))
  }

  return (
    <div className="absolute bottom-10 right-2 bg-white rounded-md px-2.5 py-1 shadow-border">

      <RadioGroup value={findMapStyle(selected).url} onChange={selectStyle}>
        <RadioGroup.Label className="font-semibold text-sm">Style</RadioGroup.Label>

        {allMapStyles.map(({name, url}) => (
          <RadioGroup.Option value={url} key={url}>
            {({checked}) => (
              <span className={clsx(
                checked && 'border-indigo-800 bg-gray-50',
                'mt-0.5 mb-1 px-1.5 flex items-center cursor-pointer border-2 border-transparent rounded-md'
              )}>
                <MapIcon className={clsx(checked && "text-indigo-800", "h-4 w-4 mr-2")}/>
                {' '}
                {name}
              </span>
            )}
          </RadioGroup.Option>
        ))}

      </RadioGroup>
    </div>
  );
}

export default StyleControl;