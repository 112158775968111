import {useRef} from "react";
import {ControlPosition, MapboxEvent, useControl} from 'react-map-gl';
import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawModeChangeEvent,
  DrawUpdateEvent
} from '@mapbox/mapbox-gl-draw';

// Types ----

export type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onLoad?: (event: MapboxEvent, control: MapboxDraw) => void;
  onModeChange?: (event: DrawModeChangeEvent, control: MapboxDraw) => void;
  onCreate?: (event: DrawCreateEvent, control: MapboxDraw) => void;
  onUpdate?: (event: DrawUpdateEvent, control: MapboxDraw) => void;
  onDelete?: (event: DrawDeleteEvent, control: MapboxDraw) => void;
};

// Component ----

function DrawControl(props: DrawControlProps) {
  const drawRef = useRef<MapboxDraw | null>(null);

  function handleLoad(event: MapboxEvent) {
    if (props.onLoad && drawRef.current) props.onLoad(event, drawRef.current)
  }

  function handleDrawModeChange(event: DrawModeChangeEvent): void {
    if (props.onModeChange && drawRef.current) props.onModeChange(event, drawRef.current)
  }

  function handleDrawCreate(event: DrawCreateEvent): void {
    if (props.onCreate && drawRef.current) props.onCreate(event, drawRef.current)
  }

  function handleDrawUpdate(event: DrawUpdateEvent): void {
    if (props.onUpdate && drawRef.current) props.onUpdate(event, drawRef.current)
  }

  function handleDrawDelete(event: DrawDeleteEvent): void {
    if (props.onDelete && drawRef.current) props.onDelete(event, drawRef.current)
  }

  useControl<MapboxDraw>(
    // On Create
    () => {
      const drawInstance = new MapboxDraw(props);
      drawRef.current = drawInstance;

      console.debug("[Map:DrawControl] Created");

      return drawInstance;
    },

    // On Add
    ({map}) => {
      map.on('load', handleLoad)
      map.on('draw.modechange', handleDrawModeChange);
      map.on('draw.create', handleDrawCreate);
      map.on('draw.update', handleDrawUpdate);
      map.on('draw.delete', handleDrawDelete);

      console.debug("[Map:DrawControl] Added");
    },

    // On Remove
    ({map}) => {
      map.off('load', handleLoad)
      map.off('draw.modechange', handleDrawModeChange);
      map.off('draw.create', handleDrawCreate);
      map.off('draw.update', handleDrawUpdate);
      map.off('draw.delete', handleDrawDelete);

      console.debug("[Map:DrawControl] Removed");
    },

    // Control Options
    {
      position: props.position
    }
  );

  return null;
}

// Export ----

export default DrawControl;