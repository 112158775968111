import React, {CSSProperties, PropsWithChildren, useRef} from 'react';
import * as Mapbox from "react-map-gl";
import {LngLatBoundsLike} from "mapbox-gl";
import bbox from "@turf/bbox";
import {featureCollection} from "@turf/helpers";
import drawingStyles from "./drawingStyles";
import DrawControl, {DrawControlProps} from "./DrawControl";
import Map from "../Map";
import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawModeChangeEvent,
  DrawUpdateEvent
} from "@mapbox/mapbox-gl-draw";
import {Feature, FeatureCollection} from "geojson";

// Types ----

type MapHeight = CSSProperties['height']

type DrawingTools = DrawControlProps['controls']

type MapboxViewState = Mapbox.MapProps['initialViewState'] | null

export type MapEditorProps = {
  height: MapHeight
  tools?: DrawingTools
  features?: FeatureCollection | null
  singleFeature?: boolean
  initialViewState?: MapboxViewState
  onViewChanged?: (state: Mapbox.ViewState) => any
  onFeaturesCreated?: (features: Array<Feature>) => void
  onFeaturesUpdated?: (features: Array<Feature>) => void
  onFeaturesDeleted?: (features: Array<Feature>) => void
  onFeaturesChanged?: (allFeatures: Array<Feature>) => void
}

// Helpers ----

function extractFeatureIds(collection: FeatureCollection): Array<string> {
  return collection
    .features
    .map(feature => String(feature.id))
    .filter(Boolean);
}

function calculateViewState(collection: FeatureCollection, defaultViewState?: Mapbox.ViewState | null): MapboxViewState {
  if (collection.features.length < 1) {
    return defaultViewState ?? null;
  }

  return {
    bounds: bbox(collection) as LngLatBoundsLike,
    fitBoundsOptions: {
      padding: {top: 10, left: 10, bottom: 10, right: 10}
    }
  }
}

// Component ----

function MapEditor({
  height,
  tools,
  features,
  singleFeature,
  initialViewState,
  onViewChanged,
  onFeaturesCreated,
  onFeaturesUpdated,
  onFeaturesDeleted,
  onFeaturesChanged,
  children
}: PropsWithChildren<MapEditorProps>) {
  // Prepare the editable feature set
  const editableFeatures = features ?? featureCollection([]);

  // Keep a reference to the drawing control
  const drawControl = useRef<MapboxDraw | null>(null);

  // Load the editable features into the draw control
  if (drawControl.current) drawControl.current.set(editableFeatures);

  // Prepare the initial view state
  const startingViewState = initialViewState ?? calculateViewState(editableFeatures, null);

  // Define event handlers
  function handleViewChanged(event: Mapbox.ViewStateChangeEvent) {
    if (onViewChanged) onViewChanged(event.viewState)
  }

  function handleLoaded(_event: Mapbox.MapboxEvent, control: MapboxDraw) {
    drawControl.current = control;
    control.set(editableFeatures);
  }

  function handleModeChanged(event: DrawModeChangeEvent, control: MapboxDraw) {
    if (!singleFeature) return;

    const drawingToolSelected = event.mode.startsWith('draw_');
    const featuresExist = control.getAll().features.length > 1;

    if (drawingToolSelected && featuresExist) {
      control.set(featureCollection(control.getAll().features.slice(-1)))
    }
  }

  function handleFeaturesCreated(event: DrawCreateEvent) {
    if (onFeaturesCreated) onFeaturesCreated(event.features);

    if (onFeaturesChanged && drawControl.current) onFeaturesChanged(drawControl.current.getAll().features);
  }

  function handleFeaturesUpdated(event: DrawUpdateEvent) {
    if (onFeaturesUpdated) onFeaturesUpdated(event.features);

    if (onFeaturesChanged && drawControl.current) onFeaturesChanged(drawControl.current.getAll().features);
  }

  function handleFeaturesDeleted(event: DrawDeleteEvent) {
    if (onFeaturesDeleted) onFeaturesDeleted(event.features);

    if (onFeaturesChanged && drawControl.current) onFeaturesChanged(drawControl.current.getAll().features);
  }

  // Render map with drawing tools
  return (
    <Map
      height={height}
      defaultMapStyle={"mapbox://styles/mapbox/streets-v12"}
      initialViewState={startingViewState}
      onViewChanged={handleViewChanged}
      hideFeatures={extractFeatureIds(editableFeatures)}
    >

      {/* Show static map features and other controls */}
      {children}

      {/* Show drawing control on top of other features */}
      <DrawControl
        controls={tools}
        styles={drawingStyles}
        displayControlsDefault={false}
        position="top-left"
        onLoad={handleLoaded}
        onModeChange={handleModeChanged}
        onCreate={handleFeaturesCreated}
        onUpdate={handleFeaturesUpdated}
        onDelete={handleFeaturesDeleted}
      />
    </Map>
  );
}

// Export ----

export default MapEditor;