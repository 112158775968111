import React, {useState} from 'react';
import View from "../../../components/View/View";
import Button from "../../../components/Button/Button";
import api from "../../../services/api";
import {useNotification} from "../../../context/NotificationContext";
import {useNavigate} from "react-router-dom";
import {Field, Form, Formik, FormikHelpers} from "formik";
import ConfirmModal from "../../../components/ConfirmModal";
import Alert from "../../../components/Alert/Alert";
import {KeyIcon, MailIcon} from "@heroicons/react/outline";
import TimeZoneSelect from "../../../components/TimeZoneSelect";
import CountrySelect from "../../../components/CountrySelect";
import LocaleSelect from "../../../components/LocaleSelect";
import CurrencySelect from "../../../components/CurrencySelect";
import SubdomainField from "../../../components/SubdomainField";
import MapEditor from "../../../components/MapEditor";
import {featureCollection, feature} from "@turf/helpers";
import {Polygon} from "geojson";
import {PencilIcon} from "@heroicons/react/solid";

// Types ----

type ProvisionedOperator = {
  id: number
  name: string
  domain: string
  is_active: boolean
  admin_user: {
    email: string
    password: string
  }
}

type FormData = {
  operator: OperatorData
  site: SiteData
}

type OperatorData = {
  name: string
  domain: string
  email: string,
  phone?: string,
  address: {
    line1: string
    line2?: string
    line3?: string
    city: string
    postcode: string
    country: string
  },
  localisation: {
    locale: string
    currency: string
  }
}

type SiteData = {
  name: string
  timezone: string,
  area: Polygon | null,
  address: {
    line1: string
    line2?: string
    line3?: string
    city: string
    postcode: string
    country: string
  }
}

// Helpers ----

const domain = process.env.REACT_APP_DOMAIN || 'n-gage.io'

const initialValues: FormData = {
  operator: {
    name: '',
    domain: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      line2: '',
      line3: '',
      city: '',
      postcode: '',
      country: 'United Kingdom',
    },
    localisation: {
      locale: 'en',
      currency: 'GBP',
    }
  },
  site: {
    name: '',
    timezone: 'UTC',
    area: null,
    address: {
      line1: '',
      line2: '',
      line3: '',
      city: '',
      postcode: '',
      country: 'United Kingdom',
    },
  }
}

// Component ----

function OperatorCreate() {
  const [operator, setOperator] = useState<ProvisionedOperator | null>(null);

  const {notify} = useNotification();
  const navigate = useNavigate();

  // Submit the form data
  async function handleSubmit({operator, site}: FormData, {setSubmitting}: FormikHelpers<FormData>) {
    if(!site.area) {
      notify("error" as const, "Cannot provision operator", 'An area must be provided for the first site')
      return;
    }

    notify("info" as const, "Provisioning operator", `Provisioning a system for ${operator.name}...`)

    try {
      // Provision the operator
      const provisionedOperator = await api.operators.create(operator);

      // Provision first site
      await api.sites.create(provisionedOperator.id, site as SiteData & {area: Polygon});

      setOperator(provisionedOperator);
    } catch (error: unknown) {
      notify("error" as const, "Operator Provisioning Failed", `${operator.name}'s system could not be provisioned; contact support.`)
    } finally {
      setSubmitting(false);
    }
  }

  function handleDismissCredentials() {
    notify("success" as const, "Operator provisioned", `${operator?.name}'s system has been provisioned`);
    setOperator(null);
    navigate('/operators');
  }

  return (
    <View title="New Operator">

      {/* Operator credentials modal */}
      <ConfirmModal
        show={!!operator}
        title="Operator Credentials"
        onApprove={handleDismissCredentials}
        canCancel={false}
      >
        <p>
          {operator ? `${operator.name}'s` : 'The'} system has been provisioned and is ready for use.
        </p>

        <p className="mt-2">
          Administrator credentials have been emailed to the operator and also to you. For convenience,
          they're temporarily shown below.
        </p>

        <p className="mt-2">
          Once dismissed, the password will not be shown again.
        </p>

        {/* TODO: Add a clipboard button to quickly copy these to the clipboard */}
        <div className="mt-4">
          <Alert color="blue" icon={MailIcon}>
            <strong>Email</strong> {operator?.admin_user.email || 'Unknown'}
          </Alert>

          <Alert color="blue" icon={KeyIcon}>
            <strong>Password</strong> {operator?.admin_user.password || 'Unknown'}
          </Alert>
        </div>
      </ConfirmModal>

      {/* Page/form contents */}
      <div className="mt-4 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {formik => (
              <Form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200">

                  {/* Operator Identity */}
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Identity</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        This information will be used to identify the operator and set up their
                        system.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="operator.name" className="block text-sm font-medium text-gray-700">
                          Name *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="operator.name"
                            autoComplete="operator-name"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="operator.domain" className="block text-sm font-medium text-gray-700">
                          Domain *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <SubdomainField
                            type="text"
                            name="operator.domain"
                            suggestionValue={formik.values.operator.name}
                            autoComplete="operator-domain"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300"
                            min="3"
                            max="100"
                            required
                          />
                          <span
                            className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                            .{domain}
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* Contact Details */}
                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Details</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        An email address and street address must be provided.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="operator.email" className="block text-sm font-medium text-gray-700">
                          Email Address *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="email"
                            name="operator.email"
                            autoComplete="email"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="operator.phone" className="block text-sm font-medium text-gray-700">
                          Phone Number
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="operator.phone"
                            autoComplete="tel-national"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="15"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 sm:col-start-1 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="operator.address.line1" className="block text-sm font-medium text-gray-700">
                          Address (Line 1) *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="operator.address.line1"
                            autoComplete="address-line1"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="operator.address.line2" className="block text-sm font-medium text-gray-700">
                          Address (Line 2)
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="operator.address.line2"
                            autoComplete="address-line2"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="operator.address.line3" className="block text-sm font-medium text-gray-700">
                          Address (Line 3)
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="operator.address.line3"
                            autoComplete="address-line3"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-2 lg:col-start-1">
                        <label htmlFor="operator.address.city" className="block text-sm font-medium text-gray-700">
                          Town/City *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="operator.address.city"
                            autoComplete="address-level2"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2 lg:col-span-1">
                        <label htmlFor="operator.address.postcode" className="block text-sm font-medium text-gray-700">
                          Post Code *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="operator.address.postcode"
                            autoComplete="postal-code"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="10"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 lg:col-span-2 sm:col-start-1 lg:col-start-1">
                        <label htmlFor="operator.address.country" className="block text-sm font-medium text-gray-700">
                          Country *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <CountrySelect
                            value={formik.values.operator.address.country}
                            onChange={country => formik.setFieldValue('operator.address.country', country)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Localisation */}
                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Localisation</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Apply localisation settings for the operator's country or region
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                      {/* Locale */}
                      <div className="sm:col-span-2 lg:col-span-2 col-start-1 lg:col-start-1">
                        <label htmlFor="operator.localisation.locale"
                               className="block text-sm font-medium text-gray-700">
                          Locale *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <LocaleSelect
                            value={formik.values.operator.localisation.locale}
                            onChange={locale => formik.setFieldValue('operator.localisation.locale', locale)}
                            required
                          />
                        </div>
                      </div>

                      {/* Currency */}
                      <div className="sm:col-span-2 lg:col-span-2">
                        <label htmlFor="operator.localisation.currency"
                               className="block text-sm font-medium text-gray-700">
                          Currency *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <CurrencySelect
                            value={formik.values.operator.localisation.currency}
                            onChange={currency => formik.setFieldValue('operator.localisation.currency', currency)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* First Site */}
                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">First Site</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Set up the first site for this operator (they always need at least one)
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                      {/* Name */}
                      <div className="sm:col-span-4 lg:col-span-3">
                        <label htmlFor="site.name" className="block text-sm font-medium text-gray-700">
                          Name *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="site.name"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      {/* Time Zone */}
                      <div className="sm:col-span-3 lg:col-span-3 sm:col-start-1 lg:col-start-1">
                        <label htmlFor="site.timezone" className="block text-sm font-medium text-gray-700">
                          Time Zone *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <TimeZoneSelect
                            value={formik.values.site.timezone}
                            onChange={timeZone => formik.setFieldValue('site.timezone', timeZone)}
                            required
                          />
                        </div>
                      </div>

                      {/* Area */}
                      <div className="sm:col-span-6 sm:col-start-1 lg:col-span-6 lg:col-start-1">
                        <label htmlFor="site.area" className="block text-sm font-medium text-gray-700">
                          Area *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <MapEditor
                            height="32rem"
                            tools={{polygon: true}}
                            features={formik.values.site.area ? featureCollection([feature(formik.values.site.area)]) : null}
                            onFeaturesChanged={features => formik.setFieldValue('site.area', features[0]?.geometry ?? null)}
                            singleFeature
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500 flex items-center">
                          <PencilIcon className="text-gray-600 h-4 w-4 mr-1"/>
                          Draw the site area on the map using the drawing tool
                        </p>
                      </div>

                      {/* Address */}
                      <div className="sm:col-span-6 sm:col-start-1 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="site.address.line1" className="block text-sm font-medium text-gray-700">
                          Address (Line 1) *
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm overflow-hidden">
                            <Field
                              type="text"
                              name="site.address.line1"
                              autoComplete="address-line1"
                              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              max="255"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                          <label htmlFor="site.address.line2" className="block text-sm font-medium text-gray-700">
                            Address (Line 2)
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <Field
                              type="text"
                              name="site.address.line2"
                              autoComplete="address-line2"
                              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              max="255"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                          <label htmlFor="site.address.line3" className="block text-sm font-medium text-gray-700">
                            Address (Line 3)
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <Field
                              type="text"
                              name="site.address.line3"
                              autoComplete="address-line3"
                              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              max="255"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-6 lg:col-span-2 lg:col-start-1">
                          <label htmlFor="site.address.city" className="block text-sm font-medium text-gray-700">
                            Town/City *
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <Field
                              type="text"
                              name="site.address.city"
                              autoComplete="address-level2"
                              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              max="255"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 lg:col-span-1">
                          <label htmlFor="site.address.postcode" className="block text-sm font-medium text-gray-700">
                            Post Code *
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <Field
                              type="text"
                              name="site.address.postcode"
                              autoComplete="postal-code"
                              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              max="10"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3 lg:col-span-2 sm:col-start-1 lg:col-start-1">
                          <label htmlFor="site.address.country" className="block text-sm font-medium text-gray-700">
                            Country *
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <CountrySelect
                              value={formik.values.site.address.country}
                              onChange={country => formik.setFieldValue('site.address.country', country)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                {/* Form Controls */}
                <div className="pt-5">
                  <div className="flex justify-end">
                    <Button color="white" onClick={() => navigate('/operators')}>
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      color="indigo"
                      className="ml-3"
                      disabled={!formik.isValid || formik.isSubmitting}
                      showSpinner={formik.isSubmitting}
                    >
                      Provision
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </View>
  );
}

export default OperatorCreate;